.container {
  font-size: 12px;
  overflow: auto;
}

.table {
  width: 100%;
  table-layout: fixed;
}

.thead {
  margin: 0;
  position: sticky;
  z-index: 10;
  top: 0;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.th {
  text-align: left;
  padding-top: 12px;
  padding-bottom: 12px;
  font-weight: normal;
  padding-right: 20px;
  &:first-child{
    border-radius: 6px 0 0 6px;
    padding-left: 16px;
  }
  &:last-child{
    border-radius: 0 6px 6px 0;
    padding-right: 16px;
  }
}

.td {
  padding-right: 20px;
  padding-left: 0;
  height: 60px;
  &:first-child{
    padding-left: 16px;
  }
  &:last-child{
    padding-right: 16px;
  }
}

.light {
  &.container {
    color: var(--black);
  }
  .thead {
    &:first-child {
      background: var(--grey9);
    }
  }
  .td {
    border-bottom: 1px solid var(--black19);
  }
  .tr:hover {
    background-color: var(--black30);
  }
}

.dark {
  &.container {
    color: var(--white);
  }
  .thead {
    &:first-child {
      background: linear-gradient(to right, var(--black32), var(--black33));
    }
  }
  th {
    color: var(--white);
  }
  .td {
    border-bottom: 1px solid var(--black34);
  }
  .tr:hover {
    background-color: var(--black15);
  }
}